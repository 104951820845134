$(function () {

    $('.homepage-hero').lightSlider({
        item: 1,
        auto:true,
        loop: true,
        easing: 'cubic-bezier(0.25, 0, 0.25, 1)',
        speed: 600,
        pause: 4000
    });

});