/**
 * Created by jamesburnett on 28/10/15.
 */
Dropzone.options.addSaleCampaignDropzone = { // The camelized version of the ID of the form element

    // The configuration we've talked about above
    autoProcessQueue: false,
    uploadMultiple: true,
    parallelUploads: 100,
    thumbnailWidth: null,
    thumbnailHeight: null,
    forceFallback: false,
    maxFiles: 1,
    maxFilesize: 10,
    previewsContainer: 'div.dropzone-previews',
    clickable: '.js-add-sale-campaign-image',
    fallback: function() {

    },

    // The setting up of the dropzone
    init: function () {
        var myDropzone = this;

        // First change the button to actually tell Dropzone to process the queue.
        this.element.querySelector("button[type=submit]").addEventListener("click", function (e) {
            // Make sure that the form isn't actually being sent.
            e.preventDefault();
            e.stopPropagation();
            myDropzone.processQueue();
        });

        this.on("reset", function()
        {
            myDropzone.element.classList.remove("dz-started");
        });

        this.on("addedfile", function(file)
        {
           myDropzone.element.classList.add("dz-started");
        });

        //// Update the total progress bar
        //this.on("totaluploadprogress", function(progress) {
        //  document.querySelector("#total-progress .progress-bar").style.width = progress + "%";
        //});

        // Listen to the sendingmultiple event. In this case, it's the sendingmultiple event instead
        // of the sending event because uploadMultiple is set to true.
        this.on("sendingmultiple", function () {
            // Gets triggered when the form is actually being sent.
            // Hide the success button or the complete form.
        });
        this.on("successmultiple", function (files, response) {
            // Gets triggered when the files have successfully been sent.
            // Redirect user or notify of success.
            //console.log(response);
            window.location.replace( 'http://' + document.domain + "/account/sale-campaign/" + response.data.id + "/edit-products/");
        });
        this.on("errormultiple", function (files, response, xhr) {
            // Gets triggered when there was an error sending the files.
            // Maybe show form again, and notify user of error

            //var not422error = { errors : ['There seems to be a problem with the server at this time. Please refresh and try again and if this problem persists contact CC directly.']};
            //
            //this.removeAllFiles();
            //$('.dz-preview').remove();
            //
            //if( xhr.status !== 422 )
            //{
            //    response = not422error;
            //}
            //
            //
            //var errors = $("*[data-error-div]"),
            //    list = errors.find('ul');
            //list.empty();
            //$.each(response, function(key, error)
            //{
            //    list.append('<li>'+ error[0] + '</li>');
            //});
            //errors.removeClass('js-hidden').addClass('js-visible');

            processFileUploadErrors(xhr, response, files, myDropzone);
        });
    }
}

Dropzone.options.editSaleCampaignDropzone = { // The camelized version of the ID of the form element

    // The configuration we've talked about above
    autoProcessQueue: false,
    uploadMultiple: true,
    forceFallback: false,
    parallelUploads: 100,
    thumbnailWidth: null,
    thumbnailHeight: null,
    maxFiles: 1,
    maxFilesize: 10,
    previewsContainer: 'div.dropzone-previews',
    clickable: '.js-add-sale-campaign-image',
    fallback: function() {

    },

    addRemoveLinks: true,
    removedfile: function (file) {
        $('form#editProductDropzone').append('<input type="hidden" value="' + file.name + '" name="image-delete[]" />');
        var _ref;
        return (_ref = file.previewElement) != null ? _ref.parentNode.removeChild(file.previewElement) : void 0;
    },
    // The setting up of the dropzone
    init: function () {
        var myDropzone = this;

        // First change the button to actually tell Dropzone to process the queue.
        this.element.querySelector("button[type=submit]").addEventListener("click", function (e) {
            // Make sure that the form isn't actually being sent.
            if (myDropzone.getQueuedFiles().length > 0 > 0) {
                e.preventDefault();
                e.stopPropagation();

                myDropzone.processQueue();
            } else {
                myDropzone.uploadFiles([]); //send empty
            }
        });


        var mockFile = {
            name: window.bannerName,
            size: 12345
        };

        // Call the default addedfile event handler
        myDropzone.emit("addedfile", mockFile);

        // And optionally show the thumbnail of the file:
        myDropzone.emit("thumbnail", mockFile, '/images/campaigns/' + window.bannerName);


        //this.on("totaluploadprogress", function(progress) {
        //  document.querySelector("#total-progress .progress-bar").style.width = progress + "%";
        //});

        // Listen to the sendingmultiple event. In this case, it's the sendingmultiple event instead
        // of the sending event because uploadMultiple is set to true.
        this.on("sendingmultiple", function () {
            // Gets triggered when the form is actually being sent.
            // Hide the success button or the complete form.
        });
        this.on("successmultiple", function (files, response) {
            // Gets triggered when the files have successfully been sent.
            // Redirect user or notify of success.
             window.location.replace( 'http://' + document.domain + "/account/sale-campaign/" + response.data.id + "/edit-products/");
        });

        this.on("reset", function()
        {
            myDropzone.element.classList.remove("dz-started");
        });

        this.on("addedfile", function(file)
        {
           myDropzone.element.classList.add("dz-started");
        });

        this.on("errormultiple", function (files, response, xhr) {
            // Gets triggered when there was an error sending the files.
            // Maybe show form again, and notify user of error

            processFileUploadErrors(xhr, response, files, myDropzone);
            if( typeof xhr != 'undefined')
            {
                $('[name="image-delete[]"]').remove();
                grabimageagain();
            }




            function grabimageagain() {
                var mockFile = {
                    name: window.bannerName,
                    size: 12345
                };
                // Call the default addedfile event handler
                myDropzone.emit("addedfile", mockFile);
                // And optionally show the thumbnail of the file:
                myDropzone.emit("thumbnail", mockFile, '/images/campaigns/' + window.bannerName);
            }

        });
    }
}

$(function() {
   var pickerStartDate = new Pikaday({
        field: document.getElementById('startDate'),
        format: 'DD/MM/YYYY'
    });

    var pickerEndDate = new Pikaday({
        field: document.getElementById('endDate'),
        format: 'DD/MM/YYYY'
    });
});
