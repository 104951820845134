$(window).load(function () {

    // $('.product-images__image').elevateZoom({
    //     zoomType: "lens",
    //     lensShape: "square",
    //     lensSize: 200
    // });

    // Product images
    $('.product-images').lightSlider({
        item: 4,
        loop: false,
        slideMove: 1,
        easing: 'cubic-bezier(0.25, 0, 0.25, 1)',
        speed: 600,
        mode:'slide',
        onSliderLoad: function() {
            $("#lightGallery").lightGallery({
                // zoom: true
            });
        },
        responsive: [
            {
                breakpoint: 800,
                settings: {
                    item: 3,
                    slideMove: 1,
                    slideMargin: 6
                }
            },
            {
                breakpoint: 600,
                settings: {
                    item: 2,
                    slideMove: 1
                }
            }
        ]
    });




    var inGallery=false;
    var gallery = $('.lSSlideWrapper');

    if ( gallery.length ) {

       $( "body" ).mousemove(function( event ) {
           var newInGallery = mouseWithin(gallery,event.pageX,event.pageY);
           // mousenter event trigger should deliver this functionality but doesn't in
           // conjuction with elevateZom
           if (newInGallery && !inGallery) {
               // comment out if you don't want to visually show the difference
               // gallery.css( "border", "3px solid red" );
               $(".product-images__image").each(function(index) {
                   var elevateZoom=$(this).data('elevateZoom');
                   if (typeof elevateZoom !== 'undefined') {
                       elevateZoom.changeState('enable');
                   }
               });
           }
           // mouseLeave event trigger should deliver this functionality but doesn't in
           // conjunction with elevateZoom
           if (inGallery && !newInGallery) {
               // comment out if you don't want to visually show the difference
               // $(".thumbnail").css( "border", "3px solid blue" );
               $(".product-images__image").each(function(index) {
                   var elevateZoom=$(this).data('elevateZoom');
                   if (typeof elevateZoom !== 'undefined') {
                       elevateZoom.changeState('disable');
                       // $(this).removeData('elevateZoom');//remove zoom instance from image
                       // $('.zoomContainer').remove();// remove zoom container from DOM
                   }
               });
           }
           inGallery=newInGallery;
       });

    }

    function mouseWithin(bounds,x,y) {
        var offset = bounds.offset();
        var l = offset.left;
        var t = offset.top;
        var h = bounds.height();
        var w = bounds.width();

        var maxx = l + w;
        var maxy = t + h;

        return (y <= maxy && y >= t) && (x <= maxx && x >= l);
    };

    $(".product-images__image").hover(function () {
        // var elevateZoom=$(this).data('elevateZoom');
        // if (typeof elevateZoom === 'undefined') {
            $(this).elevateZoom({
                // http://www.elevateweb.co.uk/image-zoom/configuration
                zoomType: "inner",
                lensShape: "square",
                lensSize: 250,
                cursor: "crosshair",
                // zoomType: "inner",
                // cursor: "crosshair"
                // gallery: 'carousel-gallery',
                // zoomEnabled: false, // start in disabled mode
                zoomWindowWidth: 700,
                zoomWindowHeight: 700,
                zoomWindowFadeIn: 500,
                zoomWindowFadeOut: 500,
                lensFadeIn: 500,
                lensFadeOut: 500,
                // tint:true, tintColour:'#404040', tintOpacity:0.5,
                scrollZoom : true
            });
            // $(this).css( "border", "3px solid red" );
        // } else {
        //     // log('product-images__image.mousenter.zoomEnabled',elevateZoom.options.zoomEnabled);
        //     elevateZoom.changeState('enable');
        // } // if
    });

});