$(function()
{
   $('[data-attribute-settings-form="name"]').keyup(function()
   {
       var currentText = $(this).val(),
           amendedText = currentText.toLowerCase().replace(/ /g, '-').replace(/[^a-z-]/g, '');

       $('[data-attribute-settings-form="slug"]').val(amendedText);
   })
});
