/**
 * Created by jamesburnett on 28/04/2015.
 */

$(document).ready(function(){



});




Dropzone.options.addProductDropzone = { // The camelized version of the ID of the form element

    // The configuration we've talked about above
    autoProcessQueue: false,
    forceFallback: false,
    uploadMultiple: true,
    parallelUploads: 100,
    maxFiles: 20,
    maxFilesize: 10,
    previewsContainer: 'div.dropzone-previews',
    clickable: '.js-add-product-image',
    addRemoveLinks: true,
    fallback: function() {

    },

    // The setting up of the dropzone
    init: function () {
        var myDropzone = this;

        // First change the button to actually tell Dropzone to process the queue.
        this.element.querySelector("button[type=submit]").addEventListener("click", function (e) {
            // Make sure that the form isn't actually being sent.

            if($('#addProductDropzone').parsley().validate())
            {
                $('.modal--loading').addClass('is-visible');
                myDropzone.processQueue();
            }
            e.preventDefault();
            e.stopPropagation();

        });

        this.on("reset", function()
        {
            myDropzone.element.classList.remove("dz-started");
        });

        this.on("addedfile", function(file)
        {
           myDropzone.element.classList.add("dz-started");
        });

        // Update the total progress bar
        this.on("totaluploadprogress", function(progress) {
          document.querySelector("#total-progress .progress-bar").style.width = progress + "%";
        });

        // Listen to the sendingmultiple event. In this case, it's the sendingmultiple event instead
        // of the sending event because uploadMultiple is set to true.
        this.on("sendingmultiple", function (files, xhr, formData) {
            console.log('===========================================');
            console.log(files);
            console.log(xhr);
            console.log(formData);
            console.log('===========================================');
            // Gets triggered when the form is actually being sent.
            // Hide the success button or the complete form.
        });
        this.on("successmultiple", function (files, response) {
            // Gets triggered when the files have successfully been sent.
            // Redirect user or notify of success.
            //console.log(response);
            window.location.replace( 'http://' + document.domain + "/account/dashboard");
        });
        this.on("errormultiple", function (files, response, xhr) {
            // Gets triggered when there was an error sending the files.
            // Maybe show form again, and notify user of error

            //var not422error = { errors : ['There seems to be a problem with the server at this time. Please refresh and try again and if this problem persists contact CC directly.']};
            //
            //console.log(files);
            //
            //this.removeAllFiles();
            //$('.dz-preview').remove();
            //
            //if( xhr.status !== 422 )
            //{
            //    response = not422error;
            //}
            //
            //var errors = $("*[data-error-div]"),
            //    list = errors.find('ul');
            //list.empty();
            //$.each(response, function(key, error)
            //{
            //    list.append('<li>'+ error[0] + '</li>');
            //});
            //errors.removeClass('js-hidden').addClass('js-visible');
            //console.log(files);
            $('.modal--loading').removeClass('is-visible');

            $('[name="image-delete[]"]').remove();
            processFileUploadErrors(xhr, response, files, myDropzone);

        });

        $(".js-add-product-image").sortable({
            items: '.dz-preview',
            cursor: 'move',
            opacity: 0.5,
            containment: '.js-add-product-image',
            distance: 20,
            tolerance: 'pointer',
            stop: function () {

                var queue = myDropzone.files;
                var newQueue = [];

                $('.js-add-product-image .dz-preview .dz-filename [data-dz-name]').each(function (count, el) {
                    var name = el.innerHTML;
                    queue.forEach(function(file) {
                        if (file.name === name) {
                            newQueue.push(file);
                        }
                    });
                });

                myDropzone.files = newQueue;
            }
        });
    }
}

Dropzone.options.editProductDropzone = { // The camelized version of the ID of the form element

    // The configuration we've talked about above
    autoProcessQueue: false,
    uploadMultiple: true,
    forceFallback: false,
    parallelUploads: 100,
    maxFiles: 20,
    maxFilesize: 10,
    previewsContainer: 'div.dropzone-previews',
    clickable: '.js-add-product-image',
    fallback: function() {

    },

    addRemoveLinks: true,
    removedfile: function (file) {
        var id = file.id;
        if( typeof id != 'undefined')
        {
            $('form#editProductDropzone').append('<input type="hidden" value="' + id + '" name="image-delete[]" />');
        }

        var _ref;
        return (_ref = file.previewElement) != null ? _ref.parentNode.removeChild(file.previewElement) : void 0;
    },


    // The setting up of the dropzone
    init: function () {
        var myDropzone = this;

        this.on("reset", function()
        {
            myDropzone.element.classList.remove("dz-started");
        });

        this.on("addedfile", function(file)
        {
           myDropzone.element.classList.add("dz-started");
        });

        // First change the button to actually tell Dropzone to process the queue.
        this.element.querySelector("button[type=submit]").addEventListener("click", function (e) {
            // Make sure that the form isn't actually being sent.
            if (myDropzone.getQueuedFiles().length > 0 > 0) {
                e.preventDefault();
                e.stopPropagation();

                $('.modal--loading').addClass('is-visible');
                myDropzone.processQueue();
            } else {
                $('.modal--loading').addClass('is-visible');
                myDropzone.uploadFiles([]); //send empty
            }
        });

        $.getJSON('/products/images/' + window.productId + '?status=' + window.editStatus ).done(function (data) {

            $.each(data, function (index, item) {
                //// Create the mock file:
                var mockFile = {
                    name: item.image_name,
                    id: item.id,
                    size: 12345
                };

                // Call the default addedfile event handler
                myDropzone.emit("addedfile", mockFile);

                // And optionally show the thumbnail of the file:
                myDropzone.emit("thumbnail", mockFile, '/images/products/' + item.image_name);

                myDropzone.files.push(mockFile);

                // If you use the maxFiles option, make sure you adjust it to the
                // correct amount:
                //var existingFileCount = 1; // The number of files already uploaded
                //myDropzone.options.maxFiles = myDropzone.options.maxFiles - existingFileCount;
            });

        });

        this.on("totaluploadprogress", function(progress) {
          document.querySelector("#total-progress .progress-bar").style.width = progress + "%";
        });

        // Listen to the sendingmultiple event. In this case, it's the sendingmultiple event instead
        // of the sending event because uploadMultiple is set to true.
        this.on("sendingmultiple", function () {
            // Gets triggered when the form is actually being sent.
            // Hide the success button or the complete form.
        });
        this.on("successmultiple", function (files, response) {
            // Gets triggered when the files have successfully been sent.
            // Redirect user or notify of success.
            window.location.replace( 'http://' + document.domain + "/account/dashboard");
        });
        this.on("errormultiple", function (files, response, xhr) {

            $('.modal--loading').removeClass('is-visible');
            var grabimagesagain =  function (myDropzone) {
                $.getJSON('/products/images/' + window.productId + '?status=' + window.editStatus).done(function (data) {

                    $.each(data, function (index, item) {
                        //// Create the mock file:
                        var mockFile = {
                            name: item.image_name,
                            id: item.id,
                            size: 12345
                        };

                        // Call the default addedfile event handler
                        myDropzone.emit("addedfile", mockFile);

                        // And optionally show the thumbnail of the file:
                        myDropzone.emit("thumbnail", mockFile, '/images/' + item.image_name);

                        myDropzone.files.push(mockFile);

                        // If you use the maxFiles option, make sure you adjust it to the
                        // correct amount:
                        //var existingFileCount = 1; // The number of files already uploaded
                        //myDropzone.options.maxFiles = myDropzone.options.maxFiles - existingFileCount;
                    });

                });
            };

            processFileUploadErrors(xhr, response, files, myDropzone);
            if( typeof xhr != 'undefined')
            {
                $('[name="image-delete[]"]').remove();
                grabimagesagain(myDropzone);
            }


        });

        $(".js-add-product-image").sortable({
            items: '.dz-preview',
            cursor: 'move',
            opacity: 0.5,
            containment: '.js-add-product-image',
            distance: 20,
            tolerance: 'pointer',
            stop: function () {

                var queue = myDropzone.files;
                console.log(queue);
                var newQueue = [];

                $('.js-add-product-image .dz-preview .dz-filename [data-dz-name]').each(function (count, el) {
                    var name = el.innerHTML;
                    queue.forEach(function(file) {
                        if (file.name === name) {
                            newQueue.push(file);
                        }
                    });
                });

                myDropzone.files = newQueue;

								var imageOrder = $('form#editProductDropzone #imageOrder');
								if (imageOrder.length <= 0){
									$('form#editProductDropzone').append('<input type="hidden" name="imageOrder" id="imageOrder" value="" />');
									imageOrder = $('form#editProductDropzone #imageOrder');
								}

								var io = [];
								for (var index in newQueue){
									var item = newQueue[index];
									var isUpload = (item.upload != null);
									io.push({
										id : item.id,
										name : item.name,
										isUpload : isUpload
									});
								}

								//console.log(imageOrder);

								imageOrder.val( JSON.stringify( io ) );

            }
        });
    }
}

//*************************
//  Categories
//
$('#filterCategory').change(function(e)
{
    var $this = $(this),
        productCategoryId = $this.val(),
        lookupTable = $.parseJSON($this.attr('data-category-size-lookup')),
        $subCategories = $('.js-sub-categories');

    selectSizeTypes(productCategoryId, lookupTable);
    $subCategories.addClass('js-hidden').removeClass('js-visible').find('select').attr('name', 'hidden-category');
    $subCategories.find('select').each(function(idx,el)
    {
        $(el).removeAttr('data-parsley-required');
        $(el).parsley().validate();
    });
    $("*[data-parent-category-id='" + productCategoryId+ "']").addClass('js-visible').removeClass('js-hidden').find('select').attr('name', 'category');
    $("*[data-parent-category-id='" + productCategoryId+ "']").find('select').attr('data-parsley-required', '');
    $('#addProductDropzone label').matchHeight();

    if( productCategoryId == '2' || productCategoryId == '7') {
        $('.js-category-example').addClass('js-hidden').removeClass('js-visible');
        $('*[data-category-example="' + productCategoryId+ '"]').addClass('js-visible').removeClass('js-hidden');
    } else {
        $('.js-category-example').addClass('js-hidden').removeClass('js-visible');
        $('*[data-category-example="default"]').addClass('js-visible').removeClass('js-hidden');
    }
});

//*************************
//  Sizes
//

$('.js-jurisdiction').change(function(e)
{
    var jurisdictionId = $(this).val(),
        parentElement = $(this).parents('.js-size'),
        productSizeElement = parentElement.find("*[data-product-size-jurisdiction-id='" + jurisdictionId+ "']");

    $('.js-product-size').attr('name', 'hidden-size');
    $('.js-sizes').addClass('js-hidden').removeClass('js-visible');
    $('.js-sizes').find('select').each(function(idx,el)
    {
        $(el).removeAttr('data-parsley-required');
        $(el).parsley().validate();
    });

    productSizeElement.addClass('js-visible').removeClass('js-hidden').find('select').attr('name', 'size');
    productSizeElement.find('select').attr('data-parsley-required', '');
    $('#addProductDropzone label').matchHeight();

});


$('.js-category').on('change', function(e)
{
    var productCategoryId = $(this).val(),
        lookupTable = $.parseJSON($(this).attr('data-category-size-lookup')),
        designerId = $('#designer').val();

    selectModels(productCategoryId, designerId);
    //selectSizeTypes(productCategoryId, lookupTable);
});

$('.js-designer').change(function(e)
{
    var designerId = $(this).val(),
        productCategoryId = $('.js-sub-categories.js-visible').find('select').val();

    selectModels(productCategoryId, designerId);
});


var selectSizeTypes = function(productCategoryId, lookupTable)
{
    var productSizeTypeId = lookupTable[productCategoryId];

    $('.js-size').addClass('js-hidden').removeClass('js-visible');
    $('.js-sizes').addClass('js-hidden').removeClass('js-visible');

    $('.js-sizes').find('select').each(function(idx,el)
    {
        $(el).removeAttr('data-parsley-required');
        $(el).parsley().validate();
    });

    $('.js-size').find('select').each(function(idx,el)
    {
        $(el).removeAttr('data-parsley-required');
        $(el).parsley().validate();
    });


    $('.js-jurisdiction').attr('name', 'hidden-jurisdiction');
    $('js-product-size').attr('name', 'hidden-size');
    if( typeof productSizeTypeId == 'undefined')
        return false;

    var jurisdictionDiv = $("*[data-product-size-type-id='" + productSizeTypeId+ "']");

    jurisdictionDiv.addClass('js-visible').removeClass('js-hidden');
    jurisdictionDiv.find('.js-jurisdiction').attr({'name': 'jurisdiction', 'data-parsley-required': ''});

    // jurisdictionDiv.find('.js-jurisdiction').find('select').attr('data-parsley-required', '');
};

var selectModels = function(productCategoryId, designerId)
{
    if ( designerId == '' || productCategoryId == '')
        return false;

    $('.js-product-model').addClass('js-hidden').find('select').attr('name','hidden-model');
    $('*[data-model-product-category="' + productCategoryId + '"][data-model-designer="' + designerId + '"]').addClass('js-visible').removeClass('js-hidden').find('select').attr('name', 'model');
}

// Set up xe.com links for delivery prices
$('.js-optional-on-price').on('keyup', function() {
    var $this         = $(this),
        $convertLink  = $this.closest('.form__controls').find('.js-convert-delivery-rate'),
        amount        = $this.val(),
        userCurrency  = $convertLink.attr('data-currency');

    $convertLink.attr('href', 'http://www.xe.com/currencyconverter/convert/?Amount=' + amount + '&From=GBP&To=' + userCurrency);
});

var showModalOnDeleteProduct = function()
{
    $('.modal--loading').addClass('is-visible');
};

$('#deleteProductEdit').on('click', function(e)
{
    showModalOnDeleteProduct();
});

$('#deleteProduct').on('click', function(e)
{
    showModalOnDeleteProduct();
});
