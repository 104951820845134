/**
 * Created by jamesburnett on 18/06/2015.
 */
// $(function () {
$(window).load(function () {

    $('.js-follow').click(function (e) {
        e.preventDefault();
        $followButton = $(this);

        $.getJSON($(this).attr('href'), function (data) {

            $NoOfFollowers = parseInt($('.js-followers').text());
            if (data.verb == 'followed') {
                $followButton.text($followButton.text().replace('Follow', 'Unfollow'));
                $('.js-followers').text($NoOfFollowers + 1);
            }

            if (data.verb == 'unfollowed') {
                $followButton.text($followButton.text().replace('Unfollow', 'Follow'));
                $('.js-followers').text($NoOfFollowers - 1);
            }


        });

    });

});
