$(window).load(function () {

    $('*[data-js-slide-title]').on('keyup', function() {
        var val = $(this).val();

        $('*[data-js-slide-title-preview]').text(val);
    });

    $('*[data-js-slide-description]').on('keyup', function() {
        var val = $(this).val();

        $('*[data-js-slide-description-preview]').text(val);
    });

    $('*[data-js-slide-cta]').on('keyup', function() {
        var val = $(this).val();

        $('*[data-js-slide-cta-preview]').text(val);
    });


});