var processFileUploadErrors = function(xhr, response, files, myDropzone)
{
    var not422error = { errors : ['There seems to be a problem with the server at this time. Please refresh and try again and if this problem persists contact CC directly.']};




    if( typeof xhr != 'undefined' && xhr.status !== 422 )
    {
        response = not422error;
    }

    if( typeof xhr != 'undefined')
    {
        myDropzone.removeAllFiles();
        $('.dz-preview').remove();
    }

    if( typeof xhr == 'undefined' )
    {
        $.each(files, function (index, file) {
             myDropzone.removeFile(file);
        });
        response = [[response]]
    }

    var errors = $("*[data-error-div]"),
        list = errors.find('ul');
    list.empty();
    $.each(response, function(key, error)
    {
        list.append('<li>'+ error[0] + '</li>');
    });
    errors.removeClass('js-hidden').addClass('js-visible');
};

