$(window).load(function () {
// $(function() {

    // Show loading window when Parsley form submitted
    window.Parsley.on('form:submit', function() {
        $('.modal--loading').addClass('is-visible');
    });


    $('#deliveryAddress').change(function(e)
    {
        var addresses = $.parseJSON($(this).attr('data-attribute-addresses')),
            selectedValue = $(this).val(),
            selectedAddress =  $.grep(addresses, function(e){
                return e.id == selectedValue; });

        updateDeliveryAddressForm(selectedAddress[0]);
    });

    var updateDeliveryAddressForm = function(data)
    {
        $('#deliveryFullName').val(data.name);
        $('#deliveryHouseName').val(data.houseno);
        $('#deliveryAddressOne').val(data.address1);
        $('#deliveryAddressTwo').val(data.address2);
        $('#deliveryTown').val(data.town);
        $('#deliveryCounty').val(data.county);
        $('#deliveryPostcode').val(data.postcode);
        $('#deliveryCountry').val(data.country_id).trigger('change');
    }

    $('#deliveryCountry').change(function()
    {
        var countryId = $('#deliveryCountry').val();
        updateDeliveryPriceAndTotal({amount: 0});
        $.ajax({
            url: '/checkout/delivery-types-available',
            data: {countryId : countryId}
        }).done(function(data){
            updateDeliveryMethodSelectElement($.parseJSON(data));
        });
    });
    $('#deliveryMethod').change(function()
    {
        parseCountryAndDeliveryMethod();
    });

    var parseCountryAndDeliveryMethod = function()
    {
        var countryId = $('#deliveryCountry').val(),
            deliveryTypeId = $('#deliveryMethod').val();

        $.ajax({
            url: '/checkout/delivery-amount',
            data: {countryId : countryId, deliveryTypeId : deliveryTypeId}
        }).done(function(data){
            updateDeliveryPriceAndTotal($.parseJSON(data));
        });
    }

    var updateDeliveryPriceAndTotal = function(data)
    {
        // console.log(data);
        var subtotal = $('#subtotal').attr('data-attribute-subtotal'),
            deliveryPriceNode = $('#deliveryPrice'),
            totalPriceNode = $('#totalPrice'),
            deliveryPrice = deliveryPriceNode.attr('data-attribute-deliveryPrice'),
            totalPrice = totalPriceNode.attr('data-attribute-totalPrice'),
            newDelivery = parseFloat(data.amount/100),
            newTotal = newDelivery + parseFloat(subtotal);

        deliveryPriceNode.html(window.currencyHtml + newDelivery.toFixed(2));
        totalPriceNode.html(window.currencyHtml + newTotal.toFixed(2) );
    }

    var updateDeliveryMethodSelectElement = function(data)
    {
        // console.log(data);
        var deliveryMethodSelectElement = $('#deliveryMethod');
        //deliveryMethodSelectElement.empty(); // remove old options
        $('#deliveryMethod option:gt(0)').remove();
        $.each(data, function(value,key) {
          deliveryMethodSelectElement.append($("<option></option>")
             .attr("value", key.delivery_type_id).text(key.name));
        });

    }

});
