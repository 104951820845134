$(function () {

    $('.select--fillable').select2({
        tags: true
    });
 
    $.fn.select2.amd.require(['select2/compat/matcher'], function (oldMatcher) {
        $(".select--enhanced").select2({
            matcher: oldMatcher(matchStart),
            // closeOnSelect: false,
            minimumResultsForSearch: 20
        })

        $('.select--enhanced').on('select2:open', function (e) {
            $('.select2-search input').prop('focus',false);
        });
    });

    function matchStart (term, text) {
        if (text.toUpperCase().indexOf(term.toUpperCase()) == 0) {
            return true;
        }
        return false;
    }

});