//app = angular.module('ccFilters', [], function($interpolateProvider) {
//    $interpolateProvider.startSymbol('<%');
//    $interpolateProvider.endSymbol('%>');
//});
//
//app.controller('FilterController', function($scope, $http)
//{
//     $http.get('collection/categories').success(function(data)
//        {
//           $scope.categories = data;
//        });
//    $scope.updateCategory = function($categoryId)
//    {
//        $http.get('collection/categories/' + $categoryId).success(function(data)
//        {
//           $scope.categories = data;
//        });
//    }
//
//});




window.Parsley.on( 'form:validate', function (form) {
    if (!form.isValid()) {
        for (var n = 0; n < form.fields.length; n++) {
            if (form.fields[n].validationResult !== true) {
                if(window.navigator.userAgent.match(/iphone|ipad/i)) {
                    window.setTimeout(function() {
                        $("html,body").animate({
                            scrollTop: $(form.fields[n].$element[0]).offset().top
                        });
                    }, 0);
                }
                break;
            }
        }
    }
});

var paginationTopOptions = {
    name: "paginationTop",
    paginationClass: "paginationTop",
  };
var paginationBottomOptions = {
    name: "paginationBottom",
    paginationClass: "paginationBottom",
};

var options = {
    valueNames: ['condition', 'designer', 'price', 'colour', 'size', 'sizetype', 'category', 'parentcategory', 'categorysizetype', 'sold'],
    page: 24,
    plugins: [
        ListPagination(paginationTopOptions),
        ListPagination(paginationBottomOptions)
    ]
};

var productList = new List('productList', options);
var updateList = function (ordering)
    {
        var designerValues = $("#designerFilter").val();
        var colourValues = $("#colourFilter").val();
        var sizeValues = $(".js-size-select-active").val();
        var categoryValues = $("#categoryFilter").val();
        var priceLow = $('#priceFilterLow').val();
        var priceHigh = $('#priceFilterHigh').val();
        var conditionValues = [];
        $(".js-condition-filter").each(function(index,item)
        {
            if(item.checked)
            {
                conditionValues.push(item.value);
            }

        });
        if( typeof( ordering ) !== 'undefined')
        {
            productList.sort('price', {order: ordering});
            productList.sort('sold', {order: 'asc'});
        }
        productList.filter(function (item) {
            return (_.contains(designerValues, item.values().designer) || !designerValues)
            && (_.contains(colourValues, item.values().colour) || !colourValues)
            && (_.contains(sizeValues, item.values().size) || !sizeValues)
            && (_.contains(categoryValues, item.values().category) || !categoryValues)
            && (conditionValues.length == 0 || (_.contains(conditionValues, item.values().condition) || !conditionValues ))
            && (isNaN(parseFloat(priceLow)) || parseFloat(item.values().price.replace(/\u00A3/g, '')) > parseFloat(priceLow))
            && (isNaN(parseFloat(priceHigh)) || parseFloat(item.values().price.replace(/\u00A3/g, '')) < parseFloat(priceHigh))
        });
        $('.collection__product').matchHeight();
    },
    createFilters = function()
    {
        updateList();
        $("#designerFilter").change(updateList);
        $("#colourFilter").change(updateList);
        $("#categoryFilter").change(function()
        {
            var sizeFilterDiv = $('#sizeFilter'),
                formControlDiv = sizeFilterDiv.parent().parent(),
                sizeTypeId = $(this).find("option:selected").attr('data-attribute-category-size-type'),
                sizeSelect = $('*[data-attribute-size-type="'+ sizeTypeId +'"]'),
                sizeSelect2 = sizeSelect.next();


            if( sizeTypeId !== '' && typeof( sizeTypeId ) !== 'undefined' )
                formControlDiv.removeClass('js-hidden').addClass('js-visible');
            else
                formControlDiv.addClass('js-hidden').removeClass('js-visible');

            $('.js-size-filter').removeClass('js-visible').addClass('js-hidden');
            formControlDiv.find('.select2').removeClass('js-visible').removeClass('js-size-select-active').addClass('js-hidden');
            sizeSelect.removeClass('js-hidden').addClass('js-visible').addClass('js-size-select-active');
            sizeSelect2.removeClass('js-hidden').addClass('js-visible');
            updateList();


        });
        $('#conditionFilter').on('change', '.js-condition-filter', updateList);
        $('#sizeFilter').on('change', '.js-size-filter', updateList);
        $('#priceFilterLow').bind('keyup change', function(e)
        {
            updateList();
        });
        $('#priceFilterHigh').bind('keyup change', function(e)
        {
            updateList();
        });
        $('#priceHighToLow').click(function(e){
            e.preventDefault();
            updateList('desc');
        });

        $('#priceLowToHigh').click(function(e){
            e.preventDefault();
            updateList('asc');
        });

        var all_designers = [];
        var all_conditions = [];
        var all_colours = [];
        var all_sizes = {};
        var all_categories = [];
        //var all_name = [];
        _.forEach(productList.items, function(item) {
            all_designers.push(item.values().designer);
            all_colours.push(item.values().colour);
            if(typeof all_sizes[item.values().sizetype] === "undefined" && item.values().sizetype != "undefined")
                all_sizes[item.values().sizetype] = [];
            // if(typeof all_categories[item.values().parentcategory] === "undefined")
            //     all_categories[item.values().parentcategory] = [];
            all_categories.push({parentcategory:item.values().parentcategory, name:item.values().category, sizeType: item.values().categorysizetype });
            all_conditions.push(item.values().condition);
        });
        // _.forEach(productList.items, function(item) {
        //
        // });
        _.forEach(productList.items, function(item) {
            all_sizes[item.values().sizetype].push(item.values().size);
        });

        all_designers.sort();
        all_colours.sort();
        all_categories = _.sortBy(all_categories, 'parentcategory');
        all_categories = _.uniq(all_categories);
        all_categories =_.groupBy(all_categories, 'parentcategory');
        all_conditions = _.sortBy(all_conditions, function(condition){
             switch (condition) {
                 case "As new":
                     return 0;
                 case "Very good condition":
                     return 1;
                 case "Good condition":
                     return 2;
                 case "Fair condition":
                     return 3;
                 default:
                     return 4;
             }
        });

        _.forEach(_.uniq(all_designers), function(item) {
            $("#designerFilter").append('<option value="' + item + '">' + item + '</option>')
        });
        _.forEach(_.uniq(all_colours), function(item) {
            $("#colourFilter").append('<option value="' + item + '">' + item + '</option>')
        });
        _.forEach(_.uniq(all_sizes), function(item) {
            $("#sizeFilter").append('<option value="' + item + '">' + item + '</option>')
        });
        _.forEach(all_sizes, function(item, key) {
            $("#sizeFilter").append('<select class="js-product-filter js-size-filter select--enhanced" multiple data-placeholder="Choose a size" data-attribute-size-type="' + key + '">');
            $('*[data-attribute-size-type="'+ key +'"]').append('<option value="">All Sizes</option>');
            _.forEach(_.uniq(item), function(cat) {
                $('*[data-attribute-size-type="'+ key +'"]').append('<option value="' + cat+ '">' + cat + '</option>')
            });
            $("#sizeFilter").append('</select>');
        });

        _.forEach(all_categories, function(item, key) {
            $("#categoryFilter").append('<optgroup label="' + key + '">');
            _.forEach(_.uniq(item, 'name'), function(cat) {
                $("#categoryFilter").append('<option data-attribute-category-size-type="' + cat.sizeType + '"  value="' + cat.name + '">' + cat.name + '</option>')
            });
            $("#categoryFilter").append('</optgroup>');
        });
        _.forEach(_.uniq(all_conditions), function(item) {
            $("#conditionFilter").append('<label class="label--checkbox"><input type="checkbox" class="js-condition-filter" name="conditionFilter[]" value="' + item + '">' + item + '</input></label>')
        });
    };

$(function () {

    $('.collection__product').matchHeight();
    $('#addProductDropzone label').matchHeight();

    $('.paginationTop').click(function()
    {
        $('.collection__product').matchHeight();
    });

    // $('.paginationBottom').click(function()
    // {
    //     $('.collection__product').matchHeight();
    // });

    if( $('.list').length > 0 )
    {
        createFilters();
    }
    $('.js-add-to-ebay').click(function(e)
    {
        e.preventDefault();
        var anchorLink = $(this),
            parentElementOfAnchor = anchorLink.parent(),
            parentElementOfInput = parentElementOfAnchor.prev(),
            Input = parentElementOfInput.children('input'),
            link = Input.val(),
            errors = $("*[data-error-div]"),
            list = errors.find('ul');

        errors.addClass('js-hidden').removeClass('js-visible');
        list.empty();

        $.ajax({
            url: anchorLink.attr('href'),
            data: {'link':link},
            success: function(){
                location.reload();
            },
            error: function(jqXHR, textStatus, errorThrown) {
                list.empty();
                $.each($.parseJSON(jqXHR.responseText), function (key, error) {
                    list.append('<li>' + error[0] + '</li>');
                });
                errors.removeClass('js-hidden').addClass('js-visible');
            }

        });


    });

    $('.js-add-affiliate-link').click(function(e)
    {
        e.preventDefault();
        var anchorLink = $(this),
            parentElementOfAnchor = anchorLink.parent(),
            parentElementOfInput = parentElementOfAnchor.prev(),
            Input = parentElementOfInput.children('input'),
            link = Input.val(),
            errors = $("*[data-error-div]"),
            list = errors.find('ul');

        errors.addClass('js-hidden').removeClass('js-visible');
        list.empty();

        $.ajax({
            url: anchorLink.attr('href'),
            data: {'link':link},
            success: function(){
                location.reload();
            },
            error: function(jqXHR, textStatus, errorThrown) {
                list.empty();
                $.each($.parseJSON(jqXHR.responseText), function (key, error) {
                    list.append('<li>' + error[0] + '</li>');
                });
                errors.removeClass('js-hidden').addClass('js-visible');
            }

        });


    });

    $('.js-remove-affiliate-link').click(function(e)
    {
        e.preventDefault();
        var anchorLink = $(this),
            parentElementOfAnchor = anchorLink.parent(),
            parentElementOfInput = parentElementOfAnchor.prev(),
            Input = parentElementOfInput.children('input'),
            link = Input.val(),
            errors = $("*[data-error-div]"),
            list = errors.find('ul');

        errors.addClass('js-hidden').removeClass('js-visible');
        list.empty();

        $.ajax({
            url: anchorLink.attr('href'),
            data: {'link':link},
            success: function(){
                location.reload();
            },
            error: function(jqXHR, textStatus, errorThrown) {
                list.empty();
                $.each($.parseJSON(jqXHR.responseText), function (key, error) {
                    list.append('<li>' + error[0] + '</li>');
                });
                errors.removeClass('js-hidden').addClass('js-visible');
            }

        });


    });


    if($('*[name="price"]').length > 0) {
        $(window).on('load', calculateRates);
    }

    $('*[name="price"]').on('keyup', calculateRates);

    // $('*[name="price"]').keyup(function()
    // {
    //     var currentPrice = $(this).val()*100,
    //         //rates = [
    //         //    {"lower":0, "upper":2099, "rate":1400},
    //         //    {"lower":2100, "upper":null, "rate":3300}
    //         //],
    //         rates = JSON.parse($(this).parents('form').attr('data-commission-rates')),
    //         priceLessCommissionInput = $('*[name="priceLessCommission"]'),
    //         priceLessCommission = 0,
    //         convertPriceLessCommission = $('#convertPriceLessCommission'),
    //         userCurrency = convertPriceLessCommission.attr('data-currency'),
    //         optionalDeliveryElements = $('.js-optional-on-price'),
    //         takeDifferenceTimesRate = function(lower, upper, rate) {
    //             return (upper + 1 - lower)*(1-rate/10000)
    //         };

    //     $.each(rates, function(index, rate)
    //     {
    //         if( currentPrice > rate.upper && rate.upper !== null )
    //         {
    //             priceLessCommission += takeDifferenceTimesRate(parseInt(rate.lower), parseInt(rate.upper), parseInt(rate.rate));
    //         }

    //         if( ( rate.upper === null && currentPrice >= rate.lower ) || ( currentPrice >= rate.lower && rate.upper !== null && currentPrice <= rate.upper ) )
    //         {
    //             priceLessCommission += takeDifferenceTimesRate(parseInt(rate.lower), parseInt(currentPrice), parseInt(rate.rate));
    //         }

    //     });

    //     if(currentPrice <= 25000)
    //     {
    //         optionalDeliveryElements.each(function(idx,el)
    //         {
    //             $(el).attr('data-parsley-required', '');
    //             $(el).parsley().validate();
    //         });
    //     }

    //     else
    //     {
    //         optionalDeliveryElements.each(function(idx,el)
    //         {
    //             $(el).removeAttr('data-parsley-required');
    //             $(el).parsley().validate();
    //         });
    //     }

    //     priceLessCommissionInput.val((priceLessCommission/100).toFixed(2));

    //     convertPriceLessCommission.attr('href', 'http://www.xe.com/currencyconverter/convert/?Amount=' + (priceLessCommission/100).toFixed(2) + '&From=GBP&To=' + userCurrency);
    // });

    function calculateRates() {
        var $rateInput = $('*[name="price"]'),
            currentPrice = $rateInput.val()*100,
            //rates = [
            //    {"lower":0, "upper":2099, "rate":1400},
            //    {"lower":2100, "upper":null, "rate":3300}
            //],
            rates = JSON.parse($rateInput.parents('form').attr('data-commission-rates')),
            business = $rateInput.parents('form').attr('data-is-business'),
            priceLessCommissionInput = $('*[name="priceLessCommission"]'),
            priceLessCommission = 0,
            convertPriceLessCommission = $('#convertPriceLessCommission'),
            userCurrency = convertPriceLessCommission.attr('data-currency'),
            optionalDeliveryElements = $('.js-optional-on-price'),
            takeDifferenceTimesRate = function(lower, upper, rate) {
                return (upper + 1 - lower)*(1-rate/10000)
            };

        // console.log("Business: ");
        // console.log(business);


        if(business == 0)
        {
            if( currentPrice <= 4000 )
            {
                priceLessCommission += currentPrice - 1400;
            } else
            {
                priceLessCommission += 4000 - 1400;
            }
        }



        $.each(rates, function(index, rate)
        {
            if( currentPrice > rate.upper && rate.upper !== null )
            {
                priceLessCommission += takeDifferenceTimesRate(parseInt(rate.lower), parseInt(rate.upper), parseInt(rate.rate));
            }

            if( ( rate.upper === null && currentPrice >= rate.lower ) || ( currentPrice >= rate.lower && rate.upper !== null && currentPrice <= rate.upper ) )
            {
                priceLessCommission += takeDifferenceTimesRate(parseInt(rate.lower), parseInt(currentPrice), parseInt(rate.rate));
            }

        });

        if(currentPrice <= 25000)
        {
            $('.js-rates').removeClass('js-hidden');
            optionalDeliveryElements.each(function(idx,el)
            {
                $(el).attr('data-parsley-required', '');
                $(el).parsley().validate();
            });
        }

        else
        {
            $('.js-rates').addClass('js-hidden');
            optionalDeliveryElements.each(function(idx,el)
            {
                $(el).removeAttr('data-parsley-required');
                $(el).parsley().validate();
            });
        }

        // if(currentPrice - priceLessCommission < 1400)
        //     priceLessCommission = currentPrice - 1400;

        if(priceLessCommission < 0)
            priceLessCommission = 0;

        priceLessCommissionInput.val((priceLessCommission/100).toFixed(2));

        convertPriceLessCommission.attr('href', 'http://www.xe.com/currencyconverter/convert/?Amount=' + (priceLessCommission/100).toFixed(2) + '&From=GBP&To=' + userCurrency);
    };


    $('.js-accept-business-hopeful').click(function(e)
    {
        var $anchorLink = $(this),
            // parentElementOfAnchor = anchorLink.parent(),
            // parentElementOfInput = parentElementOfAnchor.prev(),
            // Input = parentElementOfInput.children('input'),
            $row = $anchorLink.closest('tr'),
            $commissionRateInput = $row.find('.js-commission-rate'),
            $affiliateInput = $row.find('.js-affiliate'),
            rate = $commissionRateInput.val(),
            affiliate = $affiliateInput.val();


        if (rate === '') {
            rate = 12.00;
        } else {
            rate = parseFloat(rate, 2);
        }

        if($affiliateInput[0].checked)
        {
            affiliate = 1;
        } else
        {
            affiliate = 0;
        }


        rate = rate*100;
        
        $.ajax({
            url: $anchorLink.attr('href'),
            data: {
                'rate':rate,
                'affiliate': affiliate
            },
            success: function(){
                location.reload();
            }
        });

        e.preventDefault();

    });

    $('.js-update-business').click(function(e) {
        var $anchorLink = $(this),
            // parentElementOfAnchor = anchorLink.parent(),
            // parentElementOfInput = parentElementOfAnchor.prev(),
            // Input = parentElementOfInput.children('input'),
            $row = $anchorLink.closest('tr'),
            $commissionRateInput = $row.find('.js-commission-rate'),
            $affiliateInput = $row.find('.js-affiliate'),
            rate = $commissionRateInput.val(),
            affiliate = $affiliateInput.val();

        rate = parseFloat(rate, 2);
        rate = rate*100;

        if($affiliateInput[0].checked)
        {
            affiliate = 1;
        } else
        {
            affiliate = 0;
        }

        $.ajax({
            url: $anchorLink.attr('href'),
            data: {
                'rate':rate,
                'affiliate': affiliate
            },
            success: function(){
                location.reload();
            }
        });

        e.preventDefault();

    });

    // Add datepicker to page and post forms
    // Removed as conflicts with prepopulated date
    // var picker = new Pikaday({
    //     field: document.getElementById('published_at'),
    //     format: 'DD/MM/YYYY'
    // });

    // Enhanced selects
    // $('.select--enhanced').select2();
    


    




    

});

$(window).load(function () {

    $('.flash').not('.flash--important').delay(5000).slideUp(300);

    // Add datepicker to add product form
    var picker = new Pikaday({
        field: document.getElementById('purchaseDate'),
        format: 'DD/MM/YYYY'
    });

    $('#notifications-trigger').change(function() {
        if(this.checked) {
            $.ajax('/messages/clear-notifications');
        }
    });


    window.Parsley.addValidator('checkPrice', {
        requirementType: 'regexp',
        validateString: function(value, requirement) {
            var price = $('#price').val()*100;

            if( price <= 25000 ) {
                return requirement.test(value);
            }
        },
        messages: {
            en: 'Please enter a delivery cost'
        }
    });

    $(".paginationBottom a").on("click", function () {
        $('.collection__product').matchHeight();
        $('html,body').animate({
            scrollTop: 0
        }, 5);
    });

});
