$(window).load(function () {
// $(function() {

    // When the .js-favourite button is clicked,
    // hijack the click event
    $('.js-favourite').click(function(e){
        var link   = $(this),
            url    = link.attr('href');
            path = url.split('?')[0],
            action = path.split('/').pop();

        // Change the button status immediately
        link.toggleClass('is-active');
        
        // and post to the function using Ajax
        $.ajax({
            url : url,
            type: "GET",
            success: function() {
                if ( action == 'add' ) {
                    var newurl = url.replace('add', 'remove');
                    link.attr('href', newurl);
                } else {
                    var newurl = url.replace('remove', 'add');
                    link.attr('href', newurl);
                }
            },
            error: function(jqXHR, textStatus, errorThrown) {
                // Change button status back if the request failed
                link.toggleClass('is-active');
            }
        });

        // stop the default action
        e.preventDefault();
    });


    // If on the favourites page
    // if ( window.location.pathname == '/products/favourites') {

    //     // when the unfavourite button is clicked
    //     $('.js-favourite').click(function(e){
    //         var undo     = $(this).closest('ul').next('.collection__product__action-undo');

    //         // Remove .hidden from .collection__product__action-undo and add .visible
    //         undo.toggleClass('hidden visible');
    //     });

    //     // when the undo button is clicked
    //     $('.js-undo').click(function(e){
    //         $(this).parent().toggleClass('hidden visible');

    //         $(this).closest('.collection__product').find('.js-favourite').toggleClass('is-active');
    //     });

    // }

});