/**
 * Created by jamesburnett on 02/07/2015.
 */

$('#productStatusUpdate').click(function(e){
    e.preventDefault();
    var status = $('#productStatus'),
        data = {
        productId: status.attr('data-product-id'),
        orderId: status.attr('data-order-id'),
        userId: status.attr('data-user-id'),
        statusId: status.val(),
        '_token':$('meta[name="csrf-token"]').attr('content')
    };
    $.post($('meta[name="page-url"]').attr('content') + '/account/order/status/update', data, function(data)
    {
    });
});