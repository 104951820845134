
    Dropzone.options.addSlideDropzone = { // The camelized version of the ID of the form element

        // The configuration we've talked about above
        autoProcessQueue: false,
        uploadMultiple: false,
        forceFallback: false,
        parallelUploads: 1,
        thumbnailWidth: null,
        thumbnailHeight: null,
        maxFiles: 1,
        maxFilesize: 10,
        previewsContainer: 'div.dropzone-previews',
        clickable: '.js-add-slide',
        fallback: function() {

        },

        // The setting up of the dropzone
        init: function () {
            var myDropzone = this;

            // First change the button to actually tell Dropzone to process the queue.
            this.element.querySelector("button[type=submit]").addEventListener("click", function (e) {
                // Make sure that the form isn't actually being sent.
                e.preventDefault();
                e.stopPropagation();
                myDropzone.processQueue();
            });

            this.on("reset", function()
            {
                myDropzone.element.classList.remove("dz-started");
            });

            this.on("addedfile", function(file)
            {
               myDropzone.element.classList.add("dz-started");
            });

            // Update the total progress bar
            //this.on("totaluploadprogress", function(progress) {
            //    document.querySelector("#total-progress .progress-bar").style.width = progress + "%";
            //});

            // Listen to the sendingmultiple event. In this case, it's the sendingmultiple event instead
            // of the sending event because uploadMultiple is set to true.
            this.on("sending", function () {
                // Gets triggered when the form is actually being sent.
                // Hide the success button or the complete form.
            });
            this.on("success", function (files, response) {
                // Gets triggered when the files have successfully been sent.
                // Redirect user or notify of success.
                //console.log(response);
                window.location.replace( 'http://' + document.domain + "/pages/slider/admin");
            });
            this.on("error", function (files, response, xhr) {

                processFileUploadErrors(xhr, response, files, myDropzone);
            });
        }
    };


    Dropzone.options.editSlideDropzone = { // The camelized version of the ID of the form element

        // The configuration we've talked about above
        autoProcessQueue: false,
        uploadMultiple: false,
        forceFallback: false,
        parallelUploads: 1,
        thumbnailWidth: null,
        thumbnailHeight: null,
        maxFiles: 1,
        maxFilesize: 10,
        previewsContainer: 'div.dropzone-previews',
        clickable: '.js-add-slide',
        fallback: function() {

        },

        addRemoveLinks: true,
        removedfile: function (file) {
            var id = file.id;
            $('form#editProductDropzone').append('<input type="hidden" value="' + id + '" name="image-delete[]" />');
            var _ref;
            return (_ref = file.previewElement) != null ? _ref.parentNode.removeChild(file.previewElement) : void 0;
        },
        // The setting up of the dropzone
        init: function () {
            var myDropzone = this;

            // First change the button to actually tell Dropzone to process the queue.
            this.element.querySelector("button[type=submit]").addEventListener("click", function (e) {
                // Make sure that the form isn't actually being sent.
                if (myDropzone.getQueuedFiles().length > 0 > 0) {
                    e.preventDefault();
                    e.stopPropagation();

                    myDropzone.processQueue();
                } else {
                    myDropzone.uploadFiles([]); //send empty
                }
            });

            this.on("reset", function()
            {
                myDropzone.element.classList.remove("dz-started");
            });

            this.on("addedfile", function(file)
            {
                myDropzone.element.classList.add("dz-started");
            });



            //// Create the mock file:
            var mockFile = {
                name: window.slideImage,
                size: 12345
            };

            // Call the default addedfile event handler
            myDropzone.emit("addedfile", mockFile);

            // And optionally show the thumbnail of the file:
            myDropzone.emit("thumbnail", mockFile, '/images/pages/slider/' + window.slideImage);


            //this.on("totaluploadprogress", function(progress) {
            //    document.querySelector("#total-progress .progress-bar").style.width = progress + "%";
            //});

            // Listen to the sendingmultiple event. In this case, it's the sendingmultiple event instead
            // of the sending event because uploadMultiple is set to true.
            this.on("sending", function () {
                // Gets triggered when the form is actually being sent.
                // Hide the success button or the complete form.
            });
            this.on("success", function (files, response) {
                // Gets triggered when the files have successfully been sent.
                // Redirect user or notify of success.
                window.location.replace( 'http://' + document.domain + "/pages/slider/admin");
            });
            this.on("error", function (files, response, xhr) {
                // Gets triggered when there was an error sending the files.
                // Maybe show form again, and notify user of error
                processFileUploadErrors(xhr, response, files, myDropzone);
                if( typeof xhr != 'undefined')
                {
                    $('[name="image-delete[]"]').remove();
                    grabimageagain();
                }


                function grabimageagain() {
                    var mockFile = {
                        name: window.slideImage,
                        size: 12345
                    };
                    // Call the default addedfile event handler
                    myDropzone.emit("addedfile", mockFile);
                    // And optionally show the thumbnail of the file:
                    myDropzone.emit("thumbnail", mockFile, '/images/pages/slider/' + window.slideImage);
                }

            });
        }
    };


$(function()
{
   $('#title').keyup(function()
   {
       var currentText = $(this).val(),
           amendedText = currentText.toLowerCase().replace(/ /g, '-').replace(/[^a-z-]/g, '');

       $('#titleId').val('section-' + amendedText);
   })
});