/**
 * Created by jamesburnett on 15/06/2015.
 */
$('label.label--uploads').click(function(e)
{
    e.preventDefault();
});

Dropzone.options.editTheConceptDropzone = { // The camelized version of the ID of the form element

    // The configuration we've talked about above
    maxFiles: 1,
    autoProcessQueue: true,
    uploadMultiple: false,
    forceFallback: false,
    parallelUploads: 100,
    maxFilesize: 10,
    previewsContainer: 'div.dropzone-preview-how-to-full-the-concept',
    clickable: 'label.js-how-to-full-the-concept-label',
    addRemoveLinks: true,
    fallback: function() {

    },
    removedfile: function (file) {

        return true;
    },
    // The setting up of the dropzone
    init: function () {
        var myDropzone = this;

        // Listen to the sendingmultiple event. In this case, it's the sendingmultiple event instead
        // of the sending event because uploadMultiple is set to true.
        this.on("sending", function () {
            // Gets triggered when the form is actually being sent.
            // Hide the success button or the complete form.
            console.log('sending');
        });

        this.on("reset", function()
        {
            myDropzone.element.classList.remove("dz-started");
        });

        this.on("addedfile", function(file)
        {
           myDropzone.element.classList.add("dz-started");
        });
        this.on("success", function (files, response) {
            // Gets triggered when the files have successfully been sent.
            // Redirect user or notify of success.
            //window.location.replace("http://cc.app/account/dashboard");
            var currentThumbnail = $('.js-how-to-full-the-concept'),
                prototypeThumbnail = $('.js-how-to-full-the-concept-prototype'),
                thumbnail = prototypeThumbnail.clone();


            currentThumbnail.remove();
            $('.dropzone-preview-how-to-full-the-concept').empty();
            this.removeAllFiles();

            $('.js-how-to-full-the-concept-placeholder').remove();

            thumbnail.attr('src', 'http://' + document.domain + '/build/images/how-to-full/' + response + '?' + new Date().getTime()).prependTo('label.js-how-to-full-the-concept-label').removeClass('js-how-to-full-the-concept-prototype').addClass('js-how-to-sell-1').show();

        });
        this.on("error", function (files, response, xhr) {
            // Gets triggered when there was an error sending the files.
            // Maybe show form again, and notify user of error

            processFileUploadErrors(xhr, response, files, myDropzone);
        });
    }
};

Dropzone.options.editHowPaidDropzone = { // The camelized version of the ID of the form element

    // The configuration we've talked about above
    maxFiles: 1,
    autoProcessQueue: true,
    uploadMultiple: false,
    forceFallback: false,
    parallelUploads: 100,
    maxFilesize: 10,
    previewsContainer: 'div.dropzone-preview-how-to-full-how-paid',
    clickable: 'label.js-how-to-full-how-paid-label',
    addRemoveLinks: true,
    fallback: function() {

    },
    removedfile: function (file) {

        return true;
    },
    // The setting up of the dropzone
    init: function () {
        var myDropzone = this;

        // Listen to the sendingmultiple event. In this case, it's the sendingmultiple event instead
        // of the sending event because uploadMultiple is set to true.
        this.on("sending", function () {
            // Gets triggered when the form is actually being sent.
            // Hide the success button or the complete form.
            console.log('sending');
        });

        this.on("reset", function()
        {
            myDropzone.element.classList.remove("dz-started");
        });

        this.on("addedfile", function(file)
        {
           myDropzone.element.classList.add("dz-started");
        });
        this.on("success", function (files, response) {
            // Gets triggered when the files have successfully been sent.
            // Redirect user or notify of success.
            //window.location.replace("http://cc.app/account/dashboard");
            var currentThumbnail = $('.js-how-to-full-how-paid'),
                prototypeThumbnail = $('.js-how-to-full-how-paid-prototype'),
                thumbnail = prototypeThumbnail.clone();


            currentThumbnail.remove();
            $('.dropzone-preview-how-to-full-how-paid').empty();
            this.removeAllFiles();

            $('.js-how-to-full-how-paid-placeholder').remove();

            thumbnail.attr('src', 'http://' + document.domain + '/build/images/how-to-full/' + response + '?' + new Date().getTime()).prependTo('label.js-how-to-full-how-paid-label').removeClass('js-how-to-full-how-paid-prototype').addClass('js-how-to-sell-2').show();

        });
        this.on("error", function (files, response, xhr) {
            // Gets triggered when there was an error sending the files.
            // Maybe show form again, and notify user of error

            processFileUploadErrors(xhr, response, files, myDropzone);
        });
    }
};

Dropzone.options.editHowToPrice3Dropzone = { // The camelized version of the ID of the form element

    // The configuration we've talked about above
    maxFiles: 1,
    autoProcessQueue: true,
    uploadMultiple: false,
    forceFallback: false,
    parallelUploads: 100,
    maxFilesize: 10,
    previewsContainer: 'div.dropzone-preview-how-to-full-how-to-price',
    clickable: 'label.js-how-to-full-how-to-price-label',
    addRemoveLinks: true,
    fallback: function() {

    },
    removedfile: function (file) {

        return true;
    },
    // The setting up of the dropzone
    init: function () {
        var myDropzone = this;

        // Listen to the sendingmultiple event. In this case, it's the sendingmultiple event instead
        // of the sending event because uploadMultiple is set to true.
        this.on("sending", function () {
            // Gets triggered when the form is actually being sent.
            // Hide the success button or the complete form.
            console.log('sending');
        });

        this.on("reset", function()
        {
            myDropzone.element.classList.remove("dz-started");
        });

        this.on("addedfile", function(file)
        {
           myDropzone.element.classList.add("dz-started");
        });
        this.on("success", function (files, response) {
            // Gets triggered when the files have successfully been sent.
            // Redirect user or notify of success.
            //window.location.replace("http://cc.app/account/dashboard");
            var currentThumbnail = $('.js-how-to-full-how-to-price'),
                prototypeThumbnail = $('.js-how-to-full-how-to-price-prototype'),
                thumbnail = prototypeThumbnail.clone();


            currentThumbnail.remove();
            $('.dropzone-preview-how-to-full-how-to-price').empty();
            this.removeAllFiles();

            $('.js-how-to-full-how-to-price-placeholder').remove();

            thumbnail.attr('src', 'http://' + document.domain + '/build/images/how-to-full/' + response + '?' + new Date().getTime()).prependTo('label.js-how-to-full-how-to-price-label').removeClass('js-how-to-full-how-to-price-prototype').addClass('js-how-to-sell-3').show();

        });
        this.on("error", function (files, response, xhr) {
            // Gets triggered when there was an error sending the files.
            // Maybe show form again, and notify user of error

            processFileUploadErrors(xhr, response, files, myDropzone);
        });
    }
};

Dropzone.options.editWhatNextDropzone = { // The camelized version of the ID of the form element

    // The configuration we've talked about above
    maxFiles: 1,
    autoProcessQueue: true,
    uploadMultiple: false,
    forceFallback: false,
    parallelUploads: 100,
    maxFilesize: 10,
    previewsContainer: 'div.dropzone-preview-how-to-full-what-next',
    clickable: 'label.js-how-to-full-what-next-label',
    addRemoveLinks: true,
    fallback: function() {

    },
    removedfile: function (file) {

        return true;
    },
    // The setting up of the dropzone
    init: function () {
        var myDropzone = this;

        // Listen to the sendingmultiple event. In this case, it's the sendingmultiple event instead
        // of the sending event because uploadMultiple is set to true.
        this.on("sending", function () {
            // Gets triggered when the form is actually being sent.
            // Hide the success button or the complete form.
            console.log('sending');
        });

        this.on("reset", function()
        {
            myDropzone.element.classList.remove("dz-started");
        });

        this.on("addedfile", function(file)
        {
           myDropzone.element.classList.add("dz-started");
        });
        this.on("success", function (files, response) {
            // Gets triggered when the files have successfully been sent.
            // Redirect user or notify of success.
            //window.location.replace("http://cc.app/account/dashboard");
            var currentThumbnail = $('.js-how-to-full-what-next'),
                prototypeThumbnail = $('.js-how-to-full-what-next-prototype'),
                thumbnail = prototypeThumbnail.clone();


            currentThumbnail.remove();
            $('.dropzone-preview-how-to-full-what-next').empty();
            this.removeAllFiles();

            $('.js-how-to-full-what-next-placeholder').remove();

            thumbnail.attr('src', 'http://' + document.domain + '/build/images/how-to-full/' + response + '?' + new Date().getTime()).prependTo('label.js-how-to-full-what-next-label').removeClass('js-how-to-full-what-next-prototype').addClass('js-how-to-sell-4').show();

        });
        this.on("error", function (files, response, xhr) {
            // Gets triggered when there was an error sending the files.
            // Maybe show form again, and notify user of error

            processFileUploadErrors(xhr, response, files, myDropzone);
        });
    }
};


Dropzone.options.editWhatToSellDropzone = { // The camelized version of the ID of the form element

    // The configuration we've talked about above
    maxFiles: 1,
    autoProcessQueue: true,
    uploadMultiple: false,
    forceFallback: false,
    parallelUploads: 100,
    maxFilesize: 10,
    previewsContainer: 'div.dropzone-preview-how-to-full-what-to-sell',
    clickable: 'label.js-how-to-full-what-to-sell-label',
    addRemoveLinks: true,
    fallback: function() {

    },
    removedfile: function (file) {

        return true;
    },
    // The setting up of the dropzone
    init: function () {
        var myDropzone = this;

        // Listen to the sendingmultiple event. In this case, it's the sendingmultiple event instead
        // of the sending event because uploadMultiple is set to true.
        this.on("sending", function () {
            // Gets triggered when the form is actually being sent.
            // Hide the success button or the complete form.
            console.log('sending');
        });

        this.on("reset", function()
        {
            myDropzone.element.classList.remove("dz-started");
        });

        this.on("addedfile", function(file)
        {
           myDropzone.element.classList.add("dz-started");
        });
        this.on("success", function (files, response) {
            // Gets triggered when the files have successfully been sent.
            // Redirect user or notify of success.
            //window.location.replace("http://cc.app/account/dashboard");
            var currentThumbnail = $('.js-how-to-full-what-to-sell'),
                prototypeThumbnail = $('.js-how-to-full-what-to-sell-prototype'),
                thumbnail = prototypeThumbnail.clone();


            currentThumbnail.remove();
            $('.dropzone-preview-how-to-full-what-to-sell').empty();
            this.removeAllFiles();

            $('.js-how-to-full-what-to-sell-placeholder').remove();

            thumbnail.attr('src', 'http://' + document.domain + '/build/images/how-to-full/' + response + '?' + new Date().getTime()).prependTo('label.js-how-to-full-what-to-sell-label').removeClass('js-how-to-full-what-to-sell-prototype').addClass('js-how-to-buy-1').show();

        });
        this.on("error", function (files, response, xhr) {
            // Gets triggered when there was an error sending the files.
            // Maybe show form again, and notify user of error

            processFileUploadErrors(xhr, response, files, myDropzone);
        });
    }
};


