/**
 * Created by jamesburnett on 15/06/2015.
 */
$('label.label--uploads').click(function(e)
{
    e.preventDefault();
});

Dropzone.options.editProfilesDropzone = { // The camelized version of the ID of the form element

    // The configuration we've talked about above
    maxFiles: 1,
    autoProcessQueue: true,
    uploadMultiple: false,
    forceFallback: false,
    parallelUploads: 100,
    maxFilesize: 10,
    previewsContainer: 'div.dropzone-previews',
    clickable: 'label.label--uploads',
    addRemoveLinks: true,
    fallback: function() {

    },
    removedfile: function (file) {

        return true;
    },
    // The setting up of the dropzone
    init: function () {
        var myDropzone = this;

        // Listen to the sendingmultiple event. In this case, it's the sendingmultiple event instead
        // of the sending event because uploadMultiple is set to true.
        this.on("sending", function () {
            // Gets triggered when the form is actually being sent.
            // Hide the success button or the complete form.
            console.log('sending');
        });

        this.on("reset", function()
        {
            myDropzone.element.classList.remove("dz-started");
        });

        this.on("addedfile", function(file)
        {
           myDropzone.element.classList.add("dz-started");
        });
        this.on("success", function (files, response) {
            // Gets triggered when the files have successfully been sent.
            // Redirect user or notify of success.
            //window.location.replace("http://cc.app/account/dashboard");
            var currentThumbnail = $('.js-profile-picture'),
                prototypeThumbnail = $('.js-profile-picture-prototype'),
                thumbnail = prototypeThumbnail.clone();


            currentThumbnail.remove();
            $('.dropzone-previews').empty();
            this.removeAllFiles();

            $('.js-profile-picture-placeholder').remove();

            thumbnail.attr('src', 'http://' + document.domain + '/images/profiles/' + response).prependTo('.label--uploads').removeClass('js-profile-picture-prototype').addClass('js-profile-picture').show();

        });
        this.on("error", function (files, response, xhr) {
            // Gets triggered when there was an error sending the files.
            // Maybe show form again, and notify user of error

            processFileUploadErrors(xhr, response, files, myDropzone);
        });
    }
};
