/**
 * Created by jamesburnett on 15/06/2015.
 */
$('label.label--uploads').click(function(e)
{
    e.preventDefault();
});

Dropzone.options.editHowToSell1Dropzone = { // The camelized version of the ID of the form element

    // The configuration we've talked about above
    maxFiles: 1,
    autoProcessQueue: true,
    uploadMultiple: false,
    forceFallback: false,
    parallelUploads: 100,
    maxFilesize: 10,
    previewsContainer: 'div.dropzone-preview-how-to-sell-1',
    clickable: 'label.js-how-to-sell-1-label',
    addRemoveLinks: true,
    fallback: function() {

    },
    removedfile: function (file) {

        return true;
    },
    // The setting up of the dropzone
    init: function () {
        var myDropzone = this;

        // Listen to the sendingmultiple event. In this case, it's the sendingmultiple event instead
        // of the sending event because uploadMultiple is set to true.
        this.on("sending", function () {
            // Gets triggered when the form is actually being sent.
            // Hide the success button or the complete form.
            console.log('sending');
        });

        this.on("reset", function()
        {
            myDropzone.element.classList.remove("dz-started");
        });

        this.on("addedfile", function(file)
        {
           myDropzone.element.classList.add("dz-started");
        });
        this.on("success", function (files, response) {
            // Gets triggered when the files have successfully been sent.
            // Redirect user or notify of success.
            //window.location.replace("http://cc.app/account/dashboard");
            var currentThumbnail = $('.js-how-to-sell-1'),
                prototypeThumbnail = $('.js-how-to-sell-1-prototype'),
                thumbnail = prototypeThumbnail.clone();


            currentThumbnail.remove();
            $('.dropzone-preview-how-to-sell-1').empty();
            this.removeAllFiles();

            $('.js-how-to-sell-1-placeholder').remove();

            thumbnail.attr('src', 'http://' + document.domain + '/build/images/how-to/sell/' + response + '?' + new Date().getTime()).prependTo('label.js-how-to-sell-1-label').removeClass('js-how-to-sell-1-prototype').addClass('js-how-to-sell-1').show();

        });
        this.on("error", function (files, response, xhr) {
            // Gets triggered when there was an error sending the files.
            // Maybe show form again, and notify user of error

            processFileUploadErrors(xhr, response, files, myDropzone);
        });
    }
};

Dropzone.options.editHowToSell2Dropzone = { // The camelized version of the ID of the form element

    // The configuration we've talked about above
    maxFiles: 1,
    autoProcessQueue: true,
    uploadMultiple: false,
    forceFallback: false,
    parallelUploads: 100,
    maxFilesize: 10,
    previewsContainer: 'div.dropzone-preview-how-to-sell-2',
    clickable: 'label.js-how-to-sell-2-label',
    addRemoveLinks: true,
    fallback: function() {

    },
    removedfile: function (file) {

        return true;
    },
    // The setting up of the dropzone
    init: function () {
        var myDropzone = this;

        // Listen to the sendingmultiple event. In this case, it's the sendingmultiple event instead
        // of the sending event because uploadMultiple is set to true.
        this.on("sending", function () {
            // Gets triggered when the form is actually being sent.
            // Hide the success button or the complete form.
            console.log('sending');
        });

        this.on("reset", function()
        {
            myDropzone.element.classList.remove("dz-started");
        });

        this.on("addedfile", function(file)
        {
           myDropzone.element.classList.add("dz-started");
        });
        this.on("success", function (files, response) {
            // Gets triggered when the files have successfully been sent.
            // Redirect user or notify of success.
            //window.location.replace("http://cc.app/account/dashboard");
            var currentThumbnail = $('.js-how-to-sell-2'),
                prototypeThumbnail = $('.js-how-to-sell-2-prototype'),
                thumbnail = prototypeThumbnail.clone();


            currentThumbnail.remove();
            $('.dropzone-preview-how-to-sell-2').empty();
            this.removeAllFiles();

            $('.js-how-to-sell-2-placeholder').remove();

            thumbnail.attr('src', 'http://' + document.domain + '/build/images/how-to/sell/' + response + '?' + new Date().getTime()).prependTo('label.js-how-to-sell-2-label').removeClass('js-how-to-sell-2-prototype').addClass('js-how-to-sell-2').show();

        });
        this.on("error", function (files, response, xhr) {
            // Gets triggered when there was an error sending the files.
            // Maybe show form again, and notify user of error

            processFileUploadErrors(xhr, response, files, myDropzone);
        });
    }
};

Dropzone.options.editHowToSell3Dropzone = { // The camelized version of the ID of the form element

    // The configuration we've talked about above
    maxFiles: 1,
    autoProcessQueue: true,
    uploadMultiple: false,
    forceFallback: false,
    parallelUploads: 100,
    maxFilesize: 10,
    previewsContainer: 'div.dropzone-preview-how-to-sell-3',
    clickable: 'label.js-how-to-sell-3-label',
    addRemoveLinks: true,
    fallback: function() {

    },
    removedfile: function (file) {

        return true;
    },
    // The setting up of the dropzone
    init: function () {
        var myDropzone = this;

        // Listen to the sendingmultiple event. In this case, it's the sendingmultiple event instead
        // of the sending event because uploadMultiple is set to true.
        this.on("sending", function () {
            // Gets triggered when the form is actually being sent.
            // Hide the success button or the complete form.
            console.log('sending');
        });

        this.on("reset", function()
        {
            myDropzone.element.classList.remove("dz-started");
        });

        this.on("addedfile", function(file)
        {
           myDropzone.element.classList.add("dz-started");
        });
        this.on("success", function (files, response) {
            // Gets triggered when the files have successfully been sent.
            // Redirect user or notify of success.
            //window.location.replace("http://cc.app/account/dashboard");
            var currentThumbnail = $('.js-how-to-sell-3'),
                prototypeThumbnail = $('.js-how-to-sell-3-prototype'),
                thumbnail = prototypeThumbnail.clone();


            currentThumbnail.remove();
            $('.dropzone-preview-how-to-sell-3').empty();
            this.removeAllFiles();

            $('.js-how-to-sell-3-placeholder').remove();

            thumbnail.attr('src', 'http://' + document.domain + '/build/images/how-to/sell/' + response + '?' + new Date().getTime()).prependTo('label.js-how-to-sell-3-label').removeClass('js-how-to-sell-3-prototype').addClass('js-how-to-sell-3').show();

        });
        this.on("error", function (files, response, xhr) {
            // Gets triggered when there was an error sending the files.
            // Maybe show form again, and notify user of error

            processFileUploadErrors(xhr, response, files, myDropzone);
        });
    }
};

Dropzone.options.editHowToSell4Dropzone = { // The camelized version of the ID of the form element

    // The configuration we've talked about above
    maxFiles: 1,
    autoProcessQueue: true,
    uploadMultiple: false,
    forceFallback: false,
    parallelUploads: 100,
    maxFilesize: 10,
    previewsContainer: 'div.dropzone-preview-how-to-sell-4',
    clickable: 'label.js-how-to-sell-4-label',
    addRemoveLinks: true,
    fallback: function() {

    },
    removedfile: function (file) {

        return true;
    },
    // The setting up of the dropzone
    init: function () {
        var myDropzone = this;

        // Listen to the sendingmultiple event. In this case, it's the sendingmultiple event instead
        // of the sending event because uploadMultiple is set to true.
        this.on("sending", function () {
            // Gets triggered when the form is actually being sent.
            // Hide the success button or the complete form.
            console.log('sending');
        });

        this.on("reset", function()
        {
            myDropzone.element.classList.remove("dz-started");
        });

        this.on("addedfile", function(file)
        {
           myDropzone.element.classList.add("dz-started");
        });
        this.on("success", function (files, response) {
            // Gets triggered when the files have successfully been sent.
            // Redirect user or notify of success.
            //window.location.replace("http://cc.app/account/dashboard");
            var currentThumbnail = $('.js-how-to-sell-4'),
                prototypeThumbnail = $('.js-how-to-sell-4-prototype'),
                thumbnail = prototypeThumbnail.clone();


            currentThumbnail.remove();
            $('.dropzone-preview-how-to-sell-4').empty();
            this.removeAllFiles();

            $('.js-how-to-sell-4-placeholder').remove();

            thumbnail.attr('src', 'http://' + document.domain + '/build/images/how-to/sell/' + response + '?' + new Date().getTime()).prependTo('label.js-how-to-sell-4-label').removeClass('js-how-to-sell-4-prototype').addClass('js-how-to-sell-4').show();

        });
        this.on("error", function (files, response, xhr) {
            // Gets triggered when there was an error sending the files.
            // Maybe show form again, and notify user of error

            processFileUploadErrors(xhr, response, files, myDropzone);
        });
    }
};


Dropzone.options.editHowToBuy1Dropzone = { // The camelized version of the ID of the form element

    // The configuration we've talked about above
    maxFiles: 1,
    autoProcessQueue: true,
    uploadMultiple: false,
    forceFallback: false,
    parallelUploads: 100,
    maxFilesize: 10,
    previewsContainer: 'div.dropzone-preview-how-to-buy-1',
    clickable: 'label.js-how-to-buy-1-label',
    addRemoveLinks: true,
    fallback: function() {

    },
    removedfile: function (file) {

        return true;
    },
    // The setting up of the dropzone
    init: function () {
        var myDropzone = this;

        // Listen to the sendingmultiple event. In this case, it's the sendingmultiple event instead
        // of the sending event because uploadMultiple is set to true.
        this.on("sending", function () {
            // Gets triggered when the form is actually being sent.
            // Hide the success button or the complete form.
            console.log('sending');
        });

        this.on("reset", function()
        {
            myDropzone.element.classList.remove("dz-started");
        });

        this.on("addedfile", function(file)
        {
           myDropzone.element.classList.add("dz-started");
        });
        this.on("success", function (files, response) {
            // Gets triggered when the files have successfully been sent.
            // Redirect user or notify of success.
            //window.location.replace("http://cc.app/account/dashboard");
            var currentThumbnail = $('.js-how-to-buy-1'),
                prototypeThumbnail = $('.js-how-to-buy-1-prototype'),
                thumbnail = prototypeThumbnail.clone();


            currentThumbnail.remove();
            $('.dropzone-preview-how-to-buy-1').empty();
            this.removeAllFiles();

            $('.js-how-to-buy-1-placeholder').remove();

            thumbnail.attr('src', 'http://' + document.domain + '/build/images/how-to/buy/' + response + '?' + new Date().getTime()).prependTo('label.js-how-to-buy-1-label').removeClass('js-how-to-buy-1-prototype').addClass('js-how-to-buy-1').show();

        });
        this.on("error", function (files, response, xhr) {
            // Gets triggered when there was an error sending the files.
            // Maybe show form again, and notify user of error

            processFileUploadErrors(xhr, response, files, myDropzone);
        });
    }
};

Dropzone.options.editHowToBuy2Dropzone = { // The camelized version of the ID of the form element

    // The configuration we've talked about above
    maxFiles: 1,
    autoProcessQueue: true,
    uploadMultiple: false,
    forceFallback: false,
    parallelUploads: 100,
    maxFilesize: 10,
    previewsContainer: 'div.dropzone-preview-how-to-buy-2',
    clickable: 'label.js-how-to-buy-2-label',
    addRemoveLinks: true,
    fallback: function() {

    },
    removedfile: function (file) {

        return true;
    },
    // The setting up of the dropzone
    init: function () {
        var myDropzone = this;

        // Listen to the sendingmultiple event. In this case, it's the sendingmultiple event instead
        // of the sending event because uploadMultiple is set to true.
        this.on("sending", function () {
            // Gets triggered when the form is actually being sent.
            // Hide the success button or the complete form.
            console.log('sending');
        });

        this.on("reset", function()
        {
            myDropzone.element.classList.remove("dz-started");
        });

        this.on("addedfile", function(file)
        {
           myDropzone.element.classList.add("dz-started");
        });
        this.on("success", function (files, response) {
            // Gets triggered when the files have successfully been sent.
            // Redirect user or notify of success.
            //window.location.replace("http://cc.app/account/dashboard");
            var currentThumbnail = $('.js-how-to-buy-2'),
                prototypeThumbnail = $('.js-how-to-buy-2-prototype'),
                thumbnail = prototypeThumbnail.clone();


            currentThumbnail.remove();
            $('.dropzone-preview-how-to-buy-2').empty();
            this.removeAllFiles();

            $('.js-how-to-buy-2-placeholder').remove();

            thumbnail.attr('src', 'http://' + document.domain + '/build/images/how-to/buy/' + response + '?' + new Date().getTime()).prependTo('label.js-how-to-buy-2-label').removeClass('js-how-to-buy-2-prototype').addClass('js-how-to-buy-2').show();

        });
        this.on("error", function (files, response, xhr) {
            // Gets triggered when there was an error sending the files.
            // Maybe show form again, and notify user of error

            processFileUploadErrors(xhr, response, files, myDropzone);
        });
    }
};

Dropzone.options.editHowToBuy3Dropzone = { // The camelized version of the ID of the form element

    // The configuration we've talked about above
    maxFiles: 1,
    autoProcessQueue: true,
    uploadMultiple: false,
    forceFallback: false,
    parallelUploads: 100,
    maxFilesize: 10,
    previewsContainer: 'div.dropzone-preview-how-to-buy-3',
    clickable: 'label.js-how-to-buy-3-label',
    addRemoveLinks: true,
    fallback: function() {

    },
    removedfile: function (file) {

        return true;
    },
    // The setting up of the dropzone
    init: function () {
        var myDropzone = this;

        // Listen to the sendingmultiple event. In this case, it's the sendingmultiple event instead
        // of the sending event because uploadMultiple is set to true.
        this.on("sending", function () {
            // Gets triggered when the form is actually being sent.
            // Hide the success button or the complete form.
            console.log('sending');
        });

        this.on("reset", function()
        {
            myDropzone.element.classList.remove("dz-started");
        });

        this.on("addedfile", function(file)
        {
           myDropzone.element.classList.add("dz-started");
        });
        this.on("success", function (files, response) {
            // Gets triggered when the files have successfully been sent.
            // Redirect user or notify of success.
            //window.location.replace("http://cc.app/account/dashboard");
            var currentThumbnail = $('.js-how-to-buy-3'),
                prototypeThumbnail = $('.js-how-to-buy-3-prototype'),
                thumbnail = prototypeThumbnail.clone();


            currentThumbnail.remove();
            $('.dropzone-preview-how-to-buy-3').empty();
            this.removeAllFiles();

            $('.js-how-to-buy-3-placeholder').remove();

            thumbnail.attr('src', 'http://' + document.domain + '/build/images/how-to/buy/' + response + '?' + new Date().getTime()).prependTo('label.js-how-to-buy-3-label').removeClass('js-how-to-buy-3-prototype').addClass('js-how-to-buy-3').show();

        });
        this.on("error", function (files, response, xhr) {
            // Gets triggered when there was an error sending the files.
            // Maybe show form again, and notify user of error

            processFileUploadErrors(xhr, response, files, myDropzone);
        });
    }
};

Dropzone.options.editHowToBuy4Dropzone = { // The camelized version of the ID of the form element

    // The configuration we've talked about above
    maxFiles: 1,
    autoProcessQueue: true,
    uploadMultiple: false,
    forceFallback: false,
    parallelUploads: 100,
    maxFilesize: 10,
    previewsContainer: 'div.dropzone-preview-how-to-buy-4',
    clickable: 'label.js-how-to-buy-4-label',
    addRemoveLinks: true,
    fallback: function() {

    },
    removedfile: function (file) {

        return true;
    },
    // The setting up of the dropzone
    init: function () {
        var myDropzone = this;

        // Listen to the sendingmultiple event. In this case, it's the sendingmultiple event instead
        // of the sending event because uploadMultiple is set to true.
        this.on("sending", function () {
            // Gets triggered when the form is actually being sent.
            // Hide the success button or the complete form.
            console.log('sending');
        });

        this.on("reset", function()
        {
            myDropzone.element.classList.remove("dz-started");
        });

        this.on("addedfile", function(file)
        {
           myDropzone.element.classList.add("dz-started");
        });
        this.on("success", function (files, response) {
            // Gets triggered when the files have successfully been sent.
            // Redirect user or notify of success.
            //window.location.replace("http://cc.app/account/dashboard");
            var currentThumbnail = $('.js-how-to-buy-4'),
                prototypeThumbnail = $('.js-how-to-buy-4-prototype'),
                thumbnail = prototypeThumbnail.clone();


            currentThumbnail.remove();
            $('.dropzone-preview-how-to-buy-4').empty();
            this.removeAllFiles();

            $('.js-how-to-buy-4-placeholder').remove();

            thumbnail.attr('src', 'http://' + document.domain + '/build/images/how-to/buy/' + response + '?' + new Date().getTime()).prependTo('label.js-how-to-buy-4-label').removeClass('js-how-to-buy-4-prototype').addClass('js-how-to-buy-4').show();

        });
        this.on("error", function (files, response, xhr) {
            // Gets triggered when there was an error sending the files.
            // Maybe show form again, and notify user of error

            processFileUploadErrors(xhr, response, files, myDropzone);
        });
    }
};
