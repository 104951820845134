/**
 * Created by jamesburnett on 20/07/2015.
 */
Dropzone.options.addPPPDropzone = { // The camelized version of the ID of the form element

    // The configuration we've talked about above
    autoProcessQueue: false,
    forceFallback: false,
    uploadMultiple: false,
    parallelUploads: 100,
    maxFiles: 20,
    maxFilesize: 10,
    previewsContainer: 'div.dropzone-previews',
    clickable: '.js-add-ppp',
    fallback : function() {

    },

    // The setting up of the dropzone
    init: function () {
        var myDropzone = this;



        // First change the button to actually tell Dropzone to process the queue.
        this.element.querySelector("button[type=submit]").addEventListener("click", function (e) {
            // Make sure that the form isn't actually being sent.
            e.preventDefault();
            e.stopPropagation();
            myDropzone.processQueue();
        });

        this.on("reset", function()
        {
            myDropzone.element.classList.remove("dz-started");
        });

        this.on("addedfile", function(file)
        {
           myDropzone.element.classList.add("dz-started");
        });

        // Listen to the sendingmultiple event. In this case, it's the sendingmultiple event instead
        // of the sending event because uploadMultiple is set to true.
        this.on("sendingmultiple", function () {
            // Gets triggered when the form is actually being sent.
            // Hide the success button or the complete form.
        });
        this.on("success", function (files, response) {
            // Gets triggered when the files have successfully been sent.
            // Redirect user or notify of success.

            //console.log(response);
            window.location.replace('http://' + document.domain + "/account/ppp");
        });
        this.on("error", function (files, response, xhr) {

            processFileUploadErrors(xhr, response, files, myDropzone);

        });
    }
}