/**
 * Created by jamesburnett on 22/05/2015.
 */


//$('#categoryFilter').change( function()
//    {
//        $.getJSON('api?category='+ $(this).val()).done(function(data)
//        {
//            console.log(data);
//        });
//    });


var userFilters = function() {
    var $locationFilter = $('#locationFilter'),
        $designerFilter = $('#designerFilter'),
        $searchField = $('#searchField'),
        locations = $locationFilter.val(),
        designers = $designerFilter.val(),
        search = $searchField.val(),
        url = '?';


    if( search )
    {
        url += 'search=' + search + '&';
    }

    if( locations)
    {
        var locationString = selectToString( locations );
        url += 'location=' + locationString + '&';
    }

    function selectToString(selectItems) {
        var itemsString = '',
            itemsArray = [];
        $.each(selectItems, function (index, item) {
            itemsArray.push(item);
        });
        itemsString += itemsArray.join(',');
        return itemsString;
    }

    if( designers )
    {
        var designerString = selectToString( designers );
        url += 'designer=' + designerString + '&';
    }

    window.location.href = url;
}

/**
 * User search filters
 *
 */
$('.js-user-filter').change( function()
{
    userFilters();
});

$('#userSearch').submit(function(e)
{
    e.preventDefault();

    userFilters();
});

/**
 * Product search filters
 */
$('.js-product-filter').change( function()
{

    var $locationFilter = $('#locationFilter'),
        $designerFilter = $('#designerFilter'),
        $searchField = $('#searchField'),
        locations = $locationFilter.val(),
        designers = $designerFilter.val(),
        search = $searchField.val(),
        url = '?';


    if( search )
    {
        url += 'search=' + search + '&';
    }

    if( locations)
    {
        var locationString = selectToString( locations );
        url += 'location=' + locationString + '&';
    }

    function selectToString(selectItems) {
        var itemsString = '',
            itemsArray = [];
        $.each(selectItems, function (index, item) {
            itemsArray.push(item);
        });
        itemsString += itemsArray.join(',');
        return itemsString;
    }

    if( designers )
    {
        var designerString = selectToString( designers );
        url += 'designer=' + designerString + '&';
    }

    //window.location.href = url;

});