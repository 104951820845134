// Update the display whenever the menu is opened

$(window).load(function () {
  $('#nav-trigger').on('change', function() {
      // showNavLevel();
  });
});

// function showNavLevel() {
//     var currentUrl = window.location.protocol + "//" + window.location.host + window.location.pathname;

//     // For every link in a sub-menu
//     $('.main-nav--level-1').find('.main-nav__link').each(function() {
//         // Check whether the href matches the current page URL
//         if ($(this).prop('href') == currentUrl) {
//             // Highlight the current page in the menu
//             $(this).addClass('is-current')
//                    // And show all menu levels above the current link
//                    .parents('ul')
//                    .prev('input[type=checkbox]')
//                    .prop( "checked", true );
//         }
//     });

// }